import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './QuotaItem.scss';
import QuotasProgress from './QuotaProgress.js';

function QuotaItem() {
  const { t } = useTranslation();
  const { quotasList } = useSelector((state) => state.quota);

  // Loop through each sub-array and get the object from each one
  const objectsFromQuotas = quotasList.map((subArray) => (subArray[0]));

  // Mapping through the objects and returning specific data
  const specificDataFromObjects = objectsFromQuotas.map(
    (lastObject) => {
      const { analysisCount, plannedMaxAnalysisCount, name } = lastObject;
      const quotaProgress = (analysisCount / plannedMaxAnalysisCount) * 100;
      const formatProgress = parseInt(quotaProgress, 10);
      const quotaProgressStyle = () => {
        switch (true) {
          case formatProgress < 100:
            return 'progress-bar bg-success';
          case formatProgress === 100:
            return 'progress-bar bg-warning';
          case formatProgress > 100:
            return 'progress-bar bg-danger';
          default:
            return 'progress-bar bg-success';
        }
      };

      const className = quotaProgressStyle();

      const bovineActitivy = 'Bovine Activity';
      const isBovineActitivy = name === bovineActitivy;

      if (quotaProgress === null
        || typeof quotaProgress === 'undefined'
        || Number.isNaN(quotaProgress)
        || !Number.isFinite(quotaProgress)) {
        return (
          <div key={name} className="quota_item_ctr">
            <div className="quota_item hl">{name}</div>
            <div>{isBovineActitivy ? t('Alerts.quota%NoQuota') : t('Alerts.quota%Error')}</div>
          </div>
        );
      }

      const remainingQuota = Math.max(plannedMaxAnalysisCount - analysisCount, 0);

      return !isBovineActitivy ? (
        <div key={name} className="quota_item_ctr">
          <div className="quota_item hl">{name}</div>
          <div className="item_info_ctr">
            <div className="quota_item">
              <b>{`${t('QuotaPage.quotaSubtitle')}`}</b>
              {` ${plannedMaxAnalysisCount} ${t('QuotaPage.analyses')} - `}
              <b>{`${t('QuotaPage.sentAnalyses')}`}</b>
              {` ${analysisCount} - `}
              <b>{`${t('QuotaPage.remainingAnalyses')}`}</b>
              {` ${remainingQuota}`}
            </div>
            <div className="quota_item hl">
              {formatProgress}
              %
            </div>
          </div>
          <QuotasProgress
            className={className}
            quotaProgress={quotaProgress}
            analysisCount={analysisCount}
          />
        </div>
      ) : null;
    },
  );

  return specificDataFromObjects;
}

export default QuotaItem;
